import React from 'react';
import * as styles from "./styles.module.scss";

export default function JoneSection() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Innovating how we <span>Communicate</span></h1>
        <ul className={styles.list}>
            <li>Meetings & Events info</li>
            <li>Video meetings</li>
            <li>Trainings</li>
            <li>Interactions</li>
        </ul>
        <video loop src='https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708533602/web%20video%202.mp4' poster='https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715434275/Templates/Screenshot_2024-05-11_at_3.31.07_PM_kldckz.png' muted controls />
        <p className={styles.desc}><b>Inspired by our innovative times and building on + 1000 client cases addressing:</b></p><p className={`${styles.desc} ${styles.desc2}`}> 1. The way we present has not changed much in 2 decades! <br/> 2. The way we connect virtually has still way to go</p>
      </div>
    </div>
  )
}
