import React, { useEffect, useState } from "react";
import BannerText from "../../atoms/bannerTekst";
import * as styles from "./banner.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { ImageOptimized } from "../../atoms/ImageOptimized/ImageOptimized";

function Index() {
  const [width, setWidth] = useState(800);

  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });

    function handleResize() {
      setWidth(window.innerWidth);
    }

    if (typeof window !== "undefined") {
      handleResize(); 
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const imageUrl = width < 767 
    ? "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715430242/Templates/StreamingView_1.2_1_liftcg_1_1_yqa3rr.png" // Smaller image for mobile
    : "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715430051/Templates/StreamingView_1.2_1_liftcg_1_drmjv2.png"; // Original image

  return (
    <div className={styles.bannerContain}>
      <div className={styles.text}>
        <BannerText />
      </div>
      <Link className={styles.mobileLink} to="/contact">Try Now</Link>
      <ImageOptimized src={imageUrl} alt="pres"/>
      {/* <h1 className={styles.bottomText}>Creating - Personifying - Empowering</h1> */}
      {/* <HomeSwiper /> */}
    </div>
  );
}

export default Index;
